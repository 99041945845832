.settings__api__btns {
	width: 100%;
	display: grid;
	grid-template-columns: 1.5fr 1fr;
	align-items: stretch;
	gap: 12px;
	&._loading {
		pointer-events: none;
		opacity: 0.5;
	}
}
.settings__api__btn {
	width: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	inset: 0;
}
.settings__api__key {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	padding-bottom: 10px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.5);
	> span {
		display: inline-block;
		text-align: left;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-right: auto;
	}
	> button {
		padding: 4px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: linear-gradient(90deg, #234a83 0.01%, #922f9b 100%), #27323e;
		color: #ffffff;
		font-weight: 700;
		text-transform: uppercase;
		font-size: 10px;
		border: none;
		outline: none;
		cursor: pointer;
		border-radius: 8px;
		transition: all 0.2s ease 0s;
		&._loading {
			pointer-events: none;
			opacity: 0.5;
		}
		&:hover {
			opacity: 0.7;
		}
	}
}
.settings__api__label {
	margin: 10px 0 5px 0;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #ffffff;
}