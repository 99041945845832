.projects {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .project {
    background-color: #212a33;
    background-image: url('../../assets/img/card1.png');
    background-size: cover;
    padding: 20px 25px;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 10px;

    @media (max-width: 1400px) {
      padding: 10px 15px;
    }

    &_success {
      border: 2px solid rgba(116, 205, 93, 0.5);
    }

    &_warning {
      border: 2px solid rgba(250, 180, 47, 0.6);
      background-image: url('../../assets/img/card2.png');
    }

    &_error {
      border: 2px solid rgb(230, 67, 67, 0.6);
    }

    &__title {
      display: flex;
      align-items: center;
      gap: 30px;
      margin-bottom: 25px;

      @media (max-width: 420px) {
        flex-direction: column;
        align-items: start;
        gap: 10px;
      }
    }

    &__devider {
      border: 1px solid rgba(255, 255, 255, 0.3);
      height: 90px;
      margin: 0 30px 0 40px;

      @media (max-width: 1400px) {
        display: none;
      }
    }

    &__section {
      flex-shrink: 0;

      @media (max-width: 420px) {
        flex-shrink: initial;
        width: 100%;
      }

      &:last-child {
        @media (max-width: 1400px) {
          display: none;
        }
      }

      &-gap {
        display: flex;
        gap: 40px;

        @media (max-width: 400px) {
          gap: 20px;
        }
      }
    }

    &__info {
      display: flex;
      gap: 45px;

      @media (max-width: 420px) {
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}

.project-details {
  &__tabs {
    display: flex;
    gap: 90px;

    @media (max-width: 1400px) {
      gap: 20px;
      flex-wrap: wrap;
      row-gap: 10px;
    }

    .tab {
      cursor: pointer;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.5);
      padding-bottom: 10px;
      transition: 0.2s ease color;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:hover {
        color: #fff;
      }

      &.active {
        color: #fff;
        border-bottom: 1px solid #ffffff;
      }
    }
  }

  &__stats {
    margin: 40px 0 30px;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    @media (max-width: 992px) {
      flex-direction: column;
    }

    .card {
      width: 100%;
      box-shadow: 3px 3px 3px #00000052;

      @media (max-width: 992px) {
        width: 100%;
      }
    }
  }

  &__table {
    &-filter {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;

      @media (max-width: 992px) {
        flex-direction: column;
        gap: 15px;
        width: 100%;
      }

      @media (max-width: 992px) {
        br {
          display: none;
        }
      }

      input {
        -webkit-appearance: none;
        appearance: none;
        outline: none;
        padding: 8px 30px 8px 18px;
        height: 35px;
        border: 1px solid #ffffff;
        color: #fff;
        font: 500 16px/19px 'Gilroy', sans-serif;
        border-radius: 5px;

        background: rgba(255, 255, 255, 0.1);

        &:focus {
          background-color: #593641;
        }
      }

      select {
        -webkit-appearance: none;
        appearance: none;
        outline: none;
        padding: 8px 30px 8px 18px;
        height: 35px;
        border: 1px solid #ffffff;
        color: #fff;
        font: 500 16px/19px 'Gilroy', sans-serif;
        border-radius: 5px;
        background: rgba(255, 255, 255, 0.1)
          url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8' viewBox='0 0 12 8' fill='none' %3E%0A%3Cpath d='M1 1.10962L6 6.10962L11 1.10962' stroke='white' stroke-width='2'/%3E%0A%3C/svg%3E%0A")
          no-repeat;
        background-position-x: calc(100% - 14px);
        background-position-y: 14px;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &:focus {
          background-color: #593641;
        }
      }

      .input {
        outline: none;
        border: none;
        width: 200px;
        margin-top: 39px;
        border-radius: 0px;
        background: transparent;
        border-bottom: 1px solid #ffffff;
        color: #fff;
        font: 500 16px/19px 'Gilroy', sans-serif;

        &::placeholder {
          color: #fff;
        }

        &:focus {
          border-radius: 5px;
        }

        @media (max-width: 992px) {
          width: 100%;
        }
      }
    }
  }

  &__tab {
    display: none;

    &_active {
      display: block;
    }
  }

  .project-currencies {
    display: flex;

    @media (max-width: 992px) {
      flex-direction: column;
    }

    &__table {
      width: 100%;

      @media (max-width: 992px) {
        width: 100%;
      }
    }

    .currency-wrapper {
      width: 100%;
      background: rgba(29, 33, 41, 0.3);
      -webkit-backdrop-filter: blur(25px);
      backdrop-filter: blur(25px);
      border-radius: 20px;
      padding: 30px 30px 100px;

      @media (max-width: 992px) {
        width: 100%;
        margin-top: 30px;
      }

      .custom-input_block:first-child {
        margin-top: 0;
      }
    }
  }
}
