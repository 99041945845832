.mt-16 {
	margin-top: 16px;
}
.mb-16 {
	margin-bottom: 16px;
}
.withdraw__reduce {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: rgba(29, 33, 41, 0.3);
	backdrop-filter: blur(25px);
	border-radius: 16px;
	padding: 20px 24px;
	gap: 18px;
	> p {
		font-size: 18px;
		text-align: left;
		color: #ffffff;
		font-weight: 500;
		max-width: 340px;
		line-height: 120%;
	}
	> button {
		font-family: 'Gilroy', sans-serif;
		padding: 12px 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #ffffff;
		border: none;
		outline: none;
		border-radius: 8px;
		color: #234a83;
		font-weight: 600;
		text-transform: uppercase;
		transition: all 0.2s ease 0s;
		font-size: 16px;
		cursor: pointer;
	}
	> button:hover {
		opacity: 0.8;
	}
	> button:disabled {
		opacity: 0.5;
		pointer-events: none;
	}
}
@media screen and (max-width: 575px) {
	.withdraw__reduce {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		flex-direction: column;
		padding: 12px 16px;
		gap: 16px;
		> p {
			width: 100%;
			max-width: 100%;
		}
		> button {
			width: 100%;
		}
	}
}
._loading .withdrawMethods__edit {
	pointer-events: none;
	opacity: 0.8;
}
.withdrawMethods__edit {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 12px;
	margin-top: 16px;
	transition: all 0.2s ease 0s;
	> img {
		width: 24px;
		height: 24px;
		object-fit: contain;
		object-position: center;
	}
	.switch {
		margin-left: auto;
	}
	> span {
		white-space: nowrap;
		text-align: left;
		overflow: hidden;
		text-overflow: ellipsis;
		display: block;
		flex: 1 1 0;
	}
}
.withdraw__add {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin: 24px 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.withdraw__switch {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 12px;
	margin-bottom: 20px;
	&.switch-main {
		margin-top: 0;
		> span {
			font-size: 16px;
			color: #ffffff;
		}
	}
	> span {
		text-align: left;
		font-size: 14px;
		color: #797979;
	}
}
.switch {
	position: relative;
	cursor: pointer;
	> input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		width: 0;
		height: 0;
	}
	> input:checked + span {
		background-color: #67ab53;
		&::before {
			transform: translateX(20px);
		}
	}
	span {
		display: flex;
		position: relative;
		width: 42px;
		height: 16px;
		background-color: rgba(0, 0, 0, 0.6);
		border-radius: 200px;
		transition: all 0.2s ease 0s;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			margin: auto 0;
			width: 22px;
			height: 22px;
			border-radius: 50%;
			background-color: #fff;
			transition: all 0.2s ease 0s;
		}
	}
}
.dialog__header {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 24px;
	padding-bottom: 12px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	> p {
		color: #ffffff;
		font-size: 16px;
		font-weight: 500;
	}
	> span {
		font-weight: 700;
		font-size: 16px;
		color: #54e029;
	}
}
.custom-input__result {
	label {
		color: #fbff00;
	}
	input {
		border-color: #fbff00 !important;
		color: #fbff00;
		font-weight: 700;
	}
	border-color: #fbff00;
}
.commissions {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 14px;
	margin-top: 12px;
	color: #c4c4c4;
}
.commissions__result {
	text-align: left;
	margin-top: 20px;
	color: #ffffff;
	font-size: 18px;
	font-weight: 500;
}
.cancel__btn {
	padding: 8px 10px;
	transition: 0.6s all;
	box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.32);
	border-radius: 5px;
	font-size: 15px;
	font-weight: bold;
	line-height: 16px;
	-webkit-touch-callout: none;
	color: #fff;
	background: #e64343;
	outline: none;
	border: none;
	min-height: 34px;
	cursor: pointer;
}
.cancel__btn._loading {
	pointer-events: none;
	opacity: 0.7;
}
.dialog__withdraw {
	padding-bottom: 32px !important;
	.custom-input {
		margin-top: 20px;
	}
}
.btn-full {
	width: 100%;
	margin-top: 20px;
	padding-top: 16px;
	padding-bottom: 16px;
	font-size: 18px;
	font-weight: 700;
}
.withdraw-address {
	width: 100%;
	text-align: left;
	margin: 10px 0 5px 0;
}
.modal.scroll {
	overflow-y: auto;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
}