.apiDocs {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
}
.apiDocs__container {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	gap: 24px;
}
.apiDocs__title {
	width: 100%;
	line-height: 120%;
	text-align: left;
	font-size: 42px;
}
.apiDocs__block {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	gap: 12px;
}
.apiDocs__subtitle {
	width: 100%;
	text-align: left;
	line-height: 120%;
	font-size: 32px;
}
.apiDocs__content {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	padding: 12px;
	border-radius: 20px;
	background: rgba(29, 33, 41, 0.3);
	backdrop-filter: blur(25px);
	border-radius: 20px;
	gap: 12px;
}
.api__header {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 16px;
}
.apiDocs__name {
	color: #ffffff;
	width: 100%;
	text-align: left;
	font-size: 20px;
	line-height: 120%;
}
.szh-accordion__item-content {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	transition: all 0.2s ease 0s;
}
.api__point {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	background: rgba(29, 33, 41, 0.3);
	border-radius: 16px;
	overflow: hidden;
}
.szh-accordion__item-heading {
	width: 100%;
	> button {
		width: 100%;
		background: transparent;
		padding: 0;
		margin: 0;
		outline: none;
		border: none;
		padding: 16px;
		background: #212a33;
		border-radius: 16px;
		overflow: hidden;
		cursor: pointer;
	}
}
.api__body {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	gap: 16px;
	padding: 16px;
}
.api__text {
	width: 100%;
	text-align: left;
	font-size: 16px;
	line-height: 144%;
	font-weight: 500;
	color: #ffffff;
	> span {
		border-radius: 4px;
		padding: 2px 6px;
		background-color: #ffffff;
		color: #000;
		margin: 0 4px;
	}
}
.szh-accordion__item-content > div {
	width: 100%;
}
.code {
	max-width: 100%;
	text-align: left;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 8px;
	border-radius: 8px;
	background: #212a33;
	overflow-x: auto;
}
.code__content {
	background-color: transparent !important;
}
.api__items {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	padding: 16px;
	border-radius: 12px;
	background-color: #212a33;
	font-size: 16px;
}
.api__names {
	width: 100%;
	padding-bottom: 16px;
	display: grid;
	grid-template-columns: 160px 100px 100px 1fr;
	gap: 16px;
}
.api__item {
	width: 100%;
	padding: 16px 0;
	display: grid;
	grid-template-columns: 160px 100px 100px 1fr;
	gap: 16px;
	align-items: start;
	border-top: 1px solid rgba(255, 255, 255, 0.4);
	&:last-child {
		padding-bottom: 0;
	}
	> div {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 2px;
		> p {
			color: rgb(255, 0, 0);
			font-weight: 700;
		}
	}
	> .api__type {
		width: fit-content;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 4px;
		padding: 2px 6px;
		background-color: #ffffff;
		color: #000;
	}
}
.api__default {
	color: #57ce3a;
}
.api__description {
	width: 100%;
	text-align: left;
	color: #ffffff;
	font-size: 16px;
	> span {
		color: #57ce3a;
	}
}
.api__path {
	max-width: 100%;
	text-align: left;
	border-radius: 4px;
	padding: 2px 6px;
	background-color: rgba(0, 0, 0, 0.3);
	color: #ffffff;
	font-size: 16px;
	transition: all 0.2s ease 0s;
	cursor: pointer;
	&:hover {
		background-color: rgba(0, 0, 0, 0.1);
	}
}
.api__docs {
	width: 100%;
	text-align: left;
	font-size: 16px;
}
@media screen and (max-width: 768px) {
	.apiDocs__container {
		gap: 20px;
	}
	.apiDocs__title {
		font-size: 28px;
	}
	.apiDocs__subtitle {
		font-size: 22px;
	}
	.apiDocs__content {
		border-radius: 16px;
		padding: 8px;
	}
	.api__point {
		border-radius: 8px;
	}
	.szh-accordion__item-heading {
		> button {
			padding: 10px 12px;
			border-radius: 8px;
		}
	}
	.apiDocs__name {
		font-size: 18px;
	}
	.api__body {
		padding: 12px;
		gap: 14px;
	}
	.api__text {
		font-size: 14px;
	}
	.api__items {
		font-size: 12px;
		padding: 12px;
		border-radius: 8px;
		overflow-x: auto;
	}
	.api__names {
		padding-bottom: 12px;
		grid-template-columns: 100px 70px 70px 300px;
		gap: 8px;
	}
	.api__item {
		width: fit-content;
		padding: 12px 0;
		grid-template-columns: 100px 70px 70px 300px;
		gap: 8px;
	}
	.api__path,
	.api__description {
		font-size: 14px;
	}
}
@media screen and (max-width: 520px) {
	.api__path {
		font-size: 12px;
	}
}
@media screen and (max-width: 380px) {
	.api__path {
		font-size: 10px;
	}
}
.api__text:last-child {
	> span {
		background-color: transparent;
		padding: 0;
		color: #57ce3a;
	}
}
.modal-select .dialog.dialogInfo {
	max-width: 420px;
}
.admin__note {
	width: 100%;
	text-align: left;
}
table.fixedtable tbody td sup {
	background-color: rgba(0, 0, 0, 0.35);
	padding: 4px;
	border-radius: 4px;
}