*,
*::before,
*::after {
  box-sizing: border-box;
}

@font-face {
  font-family: "Gilroy";
  font-weight: 400;
  src: url(../../assets/fonts/Gilroy-Regular.ttf);
}
@font-face {
  font-family: "Gilroy";
  font-weight: 500;
  src: url(../../assets/fonts/Gilroy-Medium.ttf);
}
@font-face {
  font-family: "Gilroy";
  font-weight: 600;
  src: url(../../assets/fonts/Gilroy-SemiBold.ttf);
}
@font-face {
  font-family: "Gilroy";
  font-weight: 700;
  src: url(../../assets/fonts/Gilroy-Bold.ttf);
}
.text {
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
}
.text_sm {
  font-size: 14px;
  line-height: 16px;
}
.text_md {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.text_lg {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
}
.text_xl {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
}
.text-muted {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 5px;
}
.text-muted_lg {
  font-size: 20px;
  line-height: 24px;
}
.text-success {
  color: #57ce3a;
}
.text-warning {
  color: #fab42f;
}
.text-error {
  color: #e64343;
}
.text-primary {
  color: #54A6F1;
}

.btn {
  padding: 12px 44px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font: 600 14px/17px "Gilroy", sans-serif;
  background-color: #212a33;
  color: #fff;
  transition: 0.2s ease background-color;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn:hover {
  background-color: #2d3a47;
}
.btn:disabled {
  background-color: #1d2129;
  color: rgba(255, 255, 255, 0.3);
  cursor: default;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.32);
  font-size: 16px;
}
.btn:disabled:hover {
  background-color: #1d2129;
}
.btn_sm {
  padding: 12px 25px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
.btn_minimal {
  padding: 10px 15px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}
.btn-primary {
  background-color: #2d3a47;
}
.btn-primary:hover {
  background-color: #3d4f60;
}
.btn-success {
  background: #67ab53;
  transition: 0.6s all;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.32);
  font-size: 16px;
}
.btn-success:hover {
  background: #35a115;
}
.btn-warning {
  font-size: 16px;
  background: #fab42f;
  transition: 0.6s all;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.32);
}
.btn-warning:hover {
  background: #e39500;
}
.btn-blue {
  background: #54a6f1;
}
.btn-blue:hover {
  background: #72bafb;
}
.btn-danger {
  background: #c03838;
}
.btn-danger:hover {
  background: #cb4949;
}
.btn_add {
  width: 40px;
  height: 40px;
  padding: 10px;
}
.btn-innactive {
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: bold;
  line-height: 16px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn-innactive_success {
  background: rgba(116, 205, 93, 0.8);
}
.btn-innactive_error {
  background: #c03838;
}
.btn-innactive_draft {
  background: #9e9e9e;
}
.btn-xs {
  padding: 8px 10px;
}

#withdraw-modal-open {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  margin-left: 90px;
}

.change-visibility {
  background: transparent;
  border: none;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
  padding: 10px;
  opacity: 0.5;
  transition: 0.2s ease opacity;
}
.change-visibility:hover {
  opacity: 1;
}

.card {
  background: linear-gradient(109.35deg, #3e6eeb 0%, #7328d3 100%);
  border-radius: 10px;
  padding: 18px 30px;
}
.card_light {
  background: linear-gradient(109.35deg, #714ae0 0%, #d35b28 100%);
}
.card__title {
  margin-bottom: 20px;
}
.card__text {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  text-shadow: 1px 2px 1px black;
}
.card__skeleton {
  position: relative;
}
.card__skeleton::before {
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 10;
  border-radius: 10px;
  background: linear-gradient(90deg, #cb5a38, #d85ee1, #cc5935);
  animation: skeleton 1s infinite reverse;
  background-size: 200%;
}
.card__skeleton_blue {
  position: relative;
}
.card__skeleton_blue::before {
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 10;
  border-radius: 10px;
  background: linear-gradient(90deg, #702bd4, #6c84ff, #6e2ed5);
  animation: skeleton 1s infinite reverse;
  background-size: 200%;
}

@keyframes skeleton {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}
.card-block {
  background: rgba(33, 42, 51, 0.5);
  border-radius: 20px;
  padding: 18px 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.card-block .custom-input {
  margin-top: 0;
}

.custom-input {
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin-top: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  transition: 0.2s ease border-color;
}
@media (max-width: 475px) {
  .custom-input {
    flex-direction: column;
    gap: 0;
    margin-top: 10px;
  }
}
.custom-input:has(input:focus) {
  border-color: #fff;
}
.custom-input_block {
  flex-direction: column;
  gap: 0;
  position: relative;
}
.custom-input_block label {
  margin-bottom: 5px !important;
}
.custom-input_block input {
  padding: 10px 0 !important;
}
.custom-input_block-view {
  margin-top: 5px;
}
.custom-input_block-btn {
  position: absolute;
  right: 0;
  bottom: 10px;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
}
.custom-input input {
  flex: 2;
  padding-top: 10px;
  padding-bottom: 10px;
  background: transparent;
  border: none;
  outline: none;
  font-family: "Gilroy", sans-serif;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}
.custom-input input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.custom-input input:-webkit-autofill, .custom-input input:-webkit-autofill:hover, .custom-input input:-webkit-autofill:focus, .custom-input input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #262b35 inset;
  -webkit-text-fill-color: #fff;
  padding-left: 10px !important;
  margin-top: 5px;
}
@media (max-width: 475px) {
  .custom-input input {
    padding: 10px 0;
  }
}
.custom-input select {
  flex: 2;
  padding-top: 10px;
  padding-bottom: 10px;
  background: transparent;
  border: none;
  outline: none;
  font-family: "Gilroy", sans-serif;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}
.custom-input select::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.custom-input select:-webkit-autofill, .custom-input select:-webkit-autofill:hover, .custom-input select:-webkit-autofill:focus, .custom-input select:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #262b35 inset;
  -webkit-text-fill-color: #fff;
  padding-left: 10px !important;
  margin-top: 5px;
}
@media (max-width: 475px) {
  .custom-input select {
    padding: 10px 0;
  }
}
.custom-input label {
  margin: 10px 0;
}

.custom-checkbox {
  width: 20px;
  height: 20px;
  position: relative;
  display: inline-block;
  border: 2px solid #fff;
}
.custom-checkbox:after {
  content: "";
  position: absolute;
  left: 2px;
  top: 4px;
  width: 12px;
  height: 10px;
  opacity: 0;
  transition: 0.1s ease opacity;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='10' viewBox='0 0 12 10' fill='none' %3E%0A%3Cpath d='M4.33317 9.08752L0.166504 4.95419L1.4915 3.67086L4.33317 6.46252L10.5082 0.337524L11.8332 1.65419L4.33317 9.08752Z' fill='white'/%3E%0A%3C/svg%3E%0A");
}
.custom-checkbox:has(input:checked):after {
  opacity: 1;
}
.custom-checkbox input {
  display: none;
}

.custom-switch {
  display: flex;
}
.custom-switch input {
  display: none;
}
.custom-switch input:checked + label::before {
  background: rgba(116, 205, 93, 0.8);
}
.custom-switch input:checked + label::after {
  transform: translateX(20px);
}
.custom-switch label {
  cursor: pointer;
  position: relative;
}
.custom-switch label::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
  background-color: #c03838;
  border-radius: 50px;
  width: 50px;
  height: 30px;
  transition: 0.2s ease background-color;
}
.custom-switch label::after {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  background: #ffffff;
  border-radius: 50px;
  left: 3px;
  top: 3px;
  transition: 0.2s ease transform;
}

.select-btn {
  font: 500 16px/19px "Gilroy", sans-serif;
  color: #fff;
  position: relative;
  opacity: 0.5;
  cursor: pointer;
  transition: 0.2s ease opacity;
  border: none;
  background: transparent;
  text-align: left;
  width: 200px;
  padding: 0;
  margin-top: 15px;
}
.select-btn:hover {
  opacity: 1;
}
.select-btn:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='10' viewBox='0 0 15 10' fill='none' %3E%0A%3Cpath d='M1.66211 1.37708L7.91602 7.63123L14.1702 1.37708' stroke='white' stroke-width='2'/%3E%0A%3C/svg%3E%0A");
  width: 15px;
  height: 10px;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: -2px;
}

.select-options {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 30px 0 0;
  padding: 0;
  list-style: none;
  font: 500 16px/19px "Gilroy", sans-serif;
  color: #fff;
}
.select-options .select-option {
  position: relative;
  opacity: 0.5;
  cursor: pointer;
  transition: 0.2s ease opacity;
}
.select-options .select-option:hover {
  opacity: 0.8;
}
.select-options .select-option:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='11' viewBox='0 0 16 11' fill='none' %3E%0A%3Cpath d='M1.31152 3.74737L6.76855 9.20452L15.1818 0.79126' stroke='white' stroke-width='2'/%3E%0A%3C/svg%3E%0A");
  width: 13px;
  height: 9px;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: -2px;
}
.select-options .select-option_active {
  opacity: 1 !important;
}
.select-options .select-option_active:after {
  content: "";
}

table.table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
table.table.no-border tbody td,
table.table.no-border tbody td {
  border: none;
}
table.table thead th {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.5);
}
table.table tbody td {
  padding: 15px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  word-break: break-all;
  font-size: 18px;
  line-height: 19px;
  text-shadow: 1px 1px 1px black;
}
table.table tbody td:not(:last-child) {
  padding-right: 30px;
}
@media (max-width: 992px) {
  table.table tbody td {
    padding-right: 5px !important;
    font-size: 16px;
    line-height: 17px;
  }
}
table.table tbody td .td-currency {
  display: flex;
  gap: 15px;
  align-items: center;
}
table.table tbody td .td-currency .td-currency__sm {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 5px;
}
table.table tbody td input[type=checkbox] {
  width: 20px;
  height: 20px;
}

table.fixedtable {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
table.fixedtable.no-border tbody td,
table.fixedtable.no-border tbody td {
  border: none;
}
table.fixedtable thead th {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.5);
}
table.fixedtable tbody td {
  padding: 15px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  word-break: break-all;
  font-size: 18px;
  line-height: 19px;
  text-shadow: 1px 1px 1px black;
}
table.fixedtable tbody td:not(:last-child) {
  padding-right: 10px;
}
@media (max-width: 992px) {
  table.fixedtable tbody td {
    padding-right: 10px !important;
    min-height: 50px;
  }
}
table.fixedtable tbody td .td-currency {
  display: flex;
  gap: 15px;
  align-items: center;
}
@media (max-width: 992px) {
  table.fixedtable tbody td .td-currency {
    flex-direction: column;
    align-items: flex-end;
  }
}
table.fixedtable tbody td .td-currency .td-currency__sm {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 5px;
}
table.fixedtable tbody td input[type=checkbox] {
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 992px) {
  table.fixedtable {
    border: 0;
  }
  table.fixedtable caption {
    font-size: 1.3em;
  }
  table.fixedtable thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  table.fixedtable tr {
    display: block;
    margin-bottom: 5px;
  }
  table.fixedtable tr:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }
  table.fixedtable td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }
  table.fixedtable td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  table.fixedtable td:last-child {
    border-bottom: 0 !important;
    padding: 25px 0 !important;
  }
}
.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.gap-20 {
  gap: 20px;
}

.gap-25 {
  gap: 25px;
}

.gap-30 {
  gap: 30px;
}

.gap-40 {
  gap: 40px;
}

.no-border {
  border: none;
}

.w-50 {
  width: 50%;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

header {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  gap: 70px;
}
header .contact-us {
  display: flex;
  align-items: center;
  gap: 15px;
  color: #fff;
  text-decoration: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (max-width: 992px) {
  header .contact-us {
    display: none;
  }
}
header .logo {
  width: 285px;
  font: 700 48px/58px "Gilroy", sans-serif;
}
@media (max-width: 1400px) {
  header .logo {
    width: auto;
    margin-left: 50px;
  }
}
header #menu__toggle {
  display: none;
}
header #menu__toggle:checked + .menu__btn > span {
  transform: rotate(45deg);
}
header #menu__toggle:checked + .menu__btn > span::before {
  top: 0;
  transform: rotate(0deg);
}
header #menu__toggle:checked + .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
}
header .menu__btn {
  position: absolute;
  top: 32px;
  left: 25px;
  width: 26px;
  height: 20px;
  cursor: pointer;
  z-index: 1;
  padding-top: 10px;
}
@media (min-width: 1400px) {
  header .menu__btn {
    display: none;
  }
}
header .menu__btn > span,
header .menu__btn > span::before,
header .menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #fff;
  transition-duration: 0.25s;
}
header .menu__btn > span::before {
  content: "";
  top: -8px;
}
header .menu__btn > span::after {
  content: "";
  top: 8px;
}

aside {
  display: inline-block;
  width: 285px;
}
@media (max-width: 1400px) {
  aside {
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    z-index: 10;
    overflow-y: auto;
    height: calc(100vh - 80px);
    transform: translateX(-100%);
    transition: 0.2s ease transform;
  }
}
aside.shown {
  transform: translateX(0);
}
aside .nav {
  background: rgba(29, 33, 41, 0.3);
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  border-radius: 20px;
  padding: 30px 25px 10px 25px;
}
@media (max-width: 1400px) {
  aside .nav {
    border-radius: 20px 20px 0 0;
    height: calc(100vh - 80px);
  }
}
aside .nav .devider {
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin: 20px 0;
}
@media (max-width: 1400px) {
  aside .nav .devider {
    margin: 15px 0;
  }
}
aside .nav .menu {
  display: flex;
  flex-direction: column;
  gap: 15px;
  list-style: none;
  padding: 0;
}
aside .nav .menu__item {
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding: 16px 16px 16px 27px;
  cursor: pointer;
  background: #212a33;
  transition: 0.2s ease background-color;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
aside .nav .menu__item:last-child {
  margin-top: 35px;
}
@media (max-width: 1400px) {
  aside .nav .menu__item:last-child {
    margin-top: 0;
  }
}
aside .nav .menu__item:hover {
  background-color: #28323d;
}
aside .nav .menu__item_active {
  background: linear-gradient(90deg, #234a83 0.01%, #922f9b 100%), #27323e;
}
aside .nav .menu__item_danger {
  background: #c03838;
}
aside .nav .menu__item_danger:hover {
  background: #e64343;
}
aside .nav .menu__item svg {
  margin-right: 15px;
}

.main {
  display: inline-block;
  margin-left: 70px;
  margin-bottom: 70px;
  vertical-align: top;
  width: calc(100% - 360px);
}
@media (max-width: 1400px) {
  .main {
    margin-left: 0;
    width: 100%;
  }
}
.main__head {
  display: flex;
  align-items: center;
  gap: 45px;
  margin-bottom: 45px;
}
@media (max-width: 460px) {
  .main__head {
    flex-direction: column-reverse;
    align-items: start;
    gap: 10px;
    margin-bottom: 20px;
  }
}
.main__head-back {
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.main__head-back:hover svg {
  transform: translateX(-2px);
}
.main__head-back svg {
  margin-right: 10px;
  transition: 0.2s ease transform;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  transition: 0.2s ease visibility, 0.2s ease opacity;
}
.modal.shown {
  opacity: 1;
  visibility: visible;
  pointer-events: visible;
}
.modal.shown .dialog {
  transform: translate(0);
}
.modal .dialog {
  max-width: 600px;
  margin: 150px auto;
  padding: 30px 40px 60px;
  background: #1D2129;
  border-radius: 15px;
  transform: translateY(-10px);
  transition: 0.2s ease transform;
}
@media (max-width: 475px) {
  .modal .dialog {
    padding: 30px 30px 40px;
  }
}
.modal .dialog__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.modal .dialog__head-title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}
.modal .dialog__head-close {
  cursor: pointer;
}
.modal .dialog__head-close:hover svg {
  transform: translateX(-2px);
}
.modal .dialog__head-close svg {
  margin-right: 10px;
  transition: 0.2s ease transform;
}
.modal .dialog__head-action {
  cursor: pointer;
}
@media (max-width: 475px) {
  .modal .dialog__body {
    padding-bottom: 30px;
  }
}
.modal-select .dialog {
  max-width: 300px;
  margin: 160px auto;
}

.projects {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.projects .project {
  background-color: #212a33;
  background-image: url("../../assets/img/card1.png");
  background-size: cover;
  padding: 20px 25px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 10px;
}
@media (max-width: 1400px) {
  .projects .project {
    padding: 10px 15px;
  }
}
.projects .project_success {
  border: 2px solid rgba(116, 205, 93, 0.5);
}
.projects .project_warning {
  border: 2px solid rgba(250, 180, 47, 0.6);
  background-image: url("../../assets/img/card2.png");
}
.projects .project_error {
  border: 2px solid rgba(230, 67, 67, 0.6);
}
.projects .project__title {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 25px;
}
@media (max-width: 420px) {
  .projects .project__title {
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }
}
.projects .project__devider {
  border: 1px solid rgba(255, 255, 255, 0.3);
  height: 90px;
  margin: 0 30px 0 40px;
}
@media (max-width: 1400px) {
  .projects .project__devider {
    display: none;
  }
}
.projects .project__section {
  flex-shrink: 0;
}
@media (max-width: 420px) {
  .projects .project__section {
    flex-shrink: initial;
    width: 100%;
  }
}
@media (max-width: 1400px) {
  .projects .project__section:last-child {
    display: none;
  }
}
.projects .project__section-gap {
  display: flex;
  gap: 40px;
}
@media (max-width: 400px) {
  .projects .project__section-gap {
    gap: 20px;
  }
}
.projects .project__info {
  display: flex;
  gap: 45px;
}
@media (max-width: 420px) {
  .projects .project__info {
    flex-direction: column;
    gap: 10px;
  }
}

.project-details__tabs {
  display: flex;
  gap: 90px;
}
@media (max-width: 1400px) {
  .project-details__tabs {
    gap: 20px;
    flex-wrap: wrap;
    row-gap: 10px;
  }
}
.project-details__tabs .tab {
  cursor: pointer;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
  padding-bottom: 10px;
  transition: 0.2s ease color;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.project-details__tabs .tab:hover {
  color: #fff;
}
.project-details__tabs .tab.active {
  color: #fff;
  border-bottom: 1px solid #ffffff;
}
.project-details__stats {
  margin: 40px 0 30px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
@media (max-width: 992px) {
  .project-details__stats {
    flex-direction: column;
  }
}
.project-details__stats .card {
  width: 100%;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3215686275);
}
@media (max-width: 992px) {
  .project-details__stats .card {
    width: 100%;
  }
}
.project-details__table-filter {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
@media (max-width: 992px) {
  .project-details__table-filter {
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }
}
@media (max-width: 992px) {
  .project-details__table-filter br {
    display: none;
  }
}
.project-details__table-filter input {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  padding: 8px 30px 8px 18px;
  height: 35px;
  border: 1px solid #ffffff;
  color: #fff;
  font: 500 16px/19px "Gilroy", sans-serif;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
}
.project-details__table-filter input:focus {
  background-color: #593641;
}
.project-details__table-filter select {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  padding: 8px 30px 8px 18px;
  height: 35px;
  border: 1px solid #ffffff;
  color: #fff;
  font: 500 16px/19px "Gilroy", sans-serif;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1) url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8' viewBox='0 0 12 8' fill='none' %3E%0A%3Cpath d='M1 1.10962L6 6.10962L11 1.10962' stroke='white' stroke-width='2'/%3E%0A%3C/svg%3E%0A") no-repeat;
  background-position-x: calc(100% - 14px);
  background-position-y: 14px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.project-details__table-filter select:focus {
  background-color: #593641;
}
.project-details__table-filter .input {
  outline: none;
  border: none;
  width: 200px;
  margin-top: 39px;
  border-radius: 0px;
  background: transparent;
  border-bottom: 1px solid #ffffff;
  color: #fff;
  font: 500 16px/19px "Gilroy", sans-serif;
}
.project-details__table-filter .input::placeholder {
  color: #fff;
}
.project-details__table-filter .input:focus {
  border-radius: 5px;
}
@media (max-width: 992px) {
  .project-details__table-filter .input {
    width: 100%;
  }
}
.project-details__tab {
  display: none;
}
.project-details__tab_active {
  display: block;
}
.project-details .project-currencies {
  display: flex;
}
@media (max-width: 992px) {
  .project-details .project-currencies {
    flex-direction: column;
  }
}
.project-details .project-currencies__table {
  width: 100%;
}
@media (max-width: 992px) {
  .project-details .project-currencies__table {
    width: 100%;
  }
}
.project-details .project-currencies .currency-wrapper {
  width: 100%;
  background: rgba(29, 33, 41, 0.3);
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  border-radius: 20px;
  padding: 30px 30px 100px;
}
@media (max-width: 992px) {
  .project-details .project-currencies .currency-wrapper {
    width: 100%;
    margin-top: 30px;
  }
}
.project-details .project-currencies .currency-wrapper .custom-input_block:first-child {
  margin-top: 0;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  gap: 8px;
}
.pagination button {
  width: 32px;
  height: 32px;
  cursor: pointer;
  font: 600 14px/20px "Gilroy", sans-serif;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(223, 227, 232, 0.2);
  border-radius: 4px;
  transition: 0.2s ease background-color;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.pagination button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.pagination button:disabled {
  cursor: default;
  background-color: rgba(255, 255, 255, 0.2) !important;
}
.pagination button:disabled:before {
  opacity: 0.4;
}
.pagination button:disabled:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.pagination-active {
  background-color: rgba(255, 255, 255, 0.4) !important;
  border-color: #fff !important;
  color: #fff !important;
}
.pagination-prev:before, .pagination-next:before {
  content: "";
  width: 9px;
  height: 13px;
  background-size: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.pagination-disabled {
  cursor: default;
  background-color: rgba(255, 255, 255, 0.2) !important;
}
.pagination-disabled:before {
  opacity: 0.4;
}
.pagination-disabled:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.pagination-prev {
  position: relative;
}
.pagination-prev:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='13' viewBox='0 0 9 13' fill='none' %3E%0A%3Cpath d='M8.15991 1.91415L3.57991 6.50415L8.15991 11.0942L6.74991 12.5042L0.749912 6.50415L6.74991 0.50415L8.15991 1.91415Z' fill='white'/%3E%0A%3C/svg%3E%0A");
}
.pagination-next {
  position: relative;
}
.pagination-next:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='13' viewBox='0 0 9 13' fill='none' %3E%0A%3Cpath d='M0.840088 1.91415L5.42009 6.50415L0.840088 11.0942L2.25009 12.5042L8.25009 6.50415L2.25009 0.50415L0.840088 1.91415Z' fill='white'/%3E%0A%3C/svg%3E%0A");
}

.mt-16 {
  margin-top: 16px;
}

.mb-16 {
  margin-bottom: 16px;
}

.withdraw__reduce {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(29, 33, 41, 0.3);
  backdrop-filter: blur(25px);
  border-radius: 16px;
  padding: 20px 24px;
  gap: 18px;
}
.withdraw__reduce > p {
  font-size: 18px;
  text-align: left;
  color: #ffffff;
  font-weight: 500;
  max-width: 340px;
  line-height: 120%;
}
.withdraw__reduce > button {
  font-family: "Gilroy", sans-serif;
  padding: 12px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: none;
  outline: none;
  border-radius: 8px;
  color: #234a83;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 0.2s ease 0s;
  font-size: 16px;
  cursor: pointer;
}
.withdraw__reduce > button:hover {
  opacity: 0.8;
}
.withdraw__reduce > button:disabled {
  opacity: 0.5;
  pointer-events: none;
}

@media screen and (max-width: 575px) {
  .withdraw__reduce {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 12px 16px;
    gap: 16px;
  }
  .withdraw__reduce > p {
    width: 100%;
    max-width: 100%;
  }
  .withdraw__reduce > button {
    width: 100%;
  }
}
._loading .withdrawMethods__edit {
  pointer-events: none;
  opacity: 0.8;
}

.withdrawMethods__edit {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-top: 16px;
  transition: all 0.2s ease 0s;
}
.withdrawMethods__edit > img {
  width: 24px;
  height: 24px;
  object-fit: contain;
  object-position: center;
}
.withdrawMethods__edit .switch {
  margin-left: auto;
}
.withdrawMethods__edit > span {
  white-space: nowrap;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  flex: 1 1 0;
}

.withdraw__add {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 24px 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.withdraw__switch {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 20px;
}
.withdraw__switch.switch-main {
  margin-top: 0;
}
.withdraw__switch.switch-main > span {
  font-size: 16px;
  color: #ffffff;
}
.withdraw__switch > span {
  text-align: left;
  font-size: 14px;
  color: #797979;
}

.switch {
  position: relative;
  cursor: pointer;
}
.switch > input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 0;
  height: 0;
}
.switch > input:checked + span {
  background-color: #67ab53;
}
.switch > input:checked + span::before {
  transform: translateX(20px);
}
.switch span {
  display: flex;
  position: relative;
  width: 42px;
  height: 16px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 200px;
  transition: all 0.2s ease 0s;
}
.switch span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto 0;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #fff;
  transition: all 0.2s ease 0s;
}

.dialog__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 24px;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.dialog__header > p {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
}
.dialog__header > span {
  font-weight: 700;
  font-size: 16px;
  color: #54e029;
}

.custom-input__result {
  border-color: #fbff00;
}
.custom-input__result label {
  color: #fbff00;
}
.custom-input__result input {
  border-color: #fbff00 !important;
  color: #fbff00;
  font-weight: 700;
}

.commissions {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  margin-top: 12px;
  color: #c4c4c4;
}

.commissions__result {
  text-align: left;
  margin-top: 20px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
}

.cancel__btn {
  padding: 8px 10px;
  transition: 0.6s all;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.32);
  border-radius: 5px;
  font-size: 15px;
  font-weight: bold;
  line-height: 16px;
  -webkit-touch-callout: none;
  color: #fff;
  background: #e64343;
  outline: none;
  border: none;
  min-height: 34px;
  cursor: pointer;
}

.cancel__btn._loading {
  pointer-events: none;
  opacity: 0.7;
}

.dialog__withdraw {
  padding-bottom: 32px !important;
}
.dialog__withdraw .custom-input {
  margin-top: 20px;
}

.btn-full {
  width: 100%;
  margin-top: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 18px;
  font-weight: 700;
}

.withdraw-address {
  width: 100%;
  text-align: left;
  margin: 10px 0 5px 0;
}

.modal.scroll {
  overflow-y: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.settings__api__btns {
  width: 100%;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  align-items: stretch;
  gap: 12px;
}
.settings__api__btns._loading {
  pointer-events: none;
  opacity: 0.5;
}

.settings__api__btn {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0;
}

.settings__api__key {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.settings__api__key > span {
  display: inline-block;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: auto;
}
.settings__api__key > button {
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #234a83 0.01%, #922f9b 100%), #27323e;
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s ease 0s;
}
.settings__api__key > button._loading {
  pointer-events: none;
  opacity: 0.5;
}
.settings__api__key > button:hover {
  opacity: 0.7;
}

.settings__api__label {
  margin: 10px 0 5px 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.apiDocs {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.apiDocs__container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
}

.apiDocs__title {
  width: 100%;
  line-height: 120%;
  text-align: left;
  font-size: 42px;
}

.apiDocs__block {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;
}

.apiDocs__subtitle {
  width: 100%;
  text-align: left;
  line-height: 120%;
  font-size: 32px;
}

.apiDocs__content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 12px;
  border-radius: 20px;
  background: rgba(29, 33, 41, 0.3);
  backdrop-filter: blur(25px);
  border-radius: 20px;
  gap: 12px;
}

.api__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.apiDocs__name {
  color: #ffffff;
  width: 100%;
  text-align: left;
  font-size: 20px;
  line-height: 120%;
}

.szh-accordion__item-content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  transition: all 0.2s ease 0s;
}

.api__point {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background: rgba(29, 33, 41, 0.3);
  border-radius: 16px;
  overflow: hidden;
}

.szh-accordion__item-heading {
  width: 100%;
}
.szh-accordion__item-heading > button {
  width: 100%;
  background: transparent;
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  padding: 16px;
  background: #212a33;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
}

.api__body {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}

.api__text {
  width: 100%;
  text-align: left;
  font-size: 16px;
  line-height: 144%;
  font-weight: 500;
  color: #ffffff;
}
.api__text > span {
  border-radius: 4px;
  padding: 2px 6px;
  background-color: #ffffff;
  color: #000;
  margin: 0 4px;
}

.szh-accordion__item-content > div {
  width: 100%;
}

.code {
  max-width: 100%;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 8px;
  border-radius: 8px;
  background: #212a33;
  overflow-x: auto;
}

.code__content {
  background-color: transparent !important;
}

.api__items {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 16px;
  border-radius: 12px;
  background-color: #212a33;
  font-size: 16px;
}

.api__names {
  width: 100%;
  padding-bottom: 16px;
  display: grid;
  grid-template-columns: 160px 100px 100px 1fr;
  gap: 16px;
}

.api__item {
  width: 100%;
  padding: 16px 0;
  display: grid;
  grid-template-columns: 160px 100px 100px 1fr;
  gap: 16px;
  align-items: start;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}
.api__item:last-child {
  padding-bottom: 0;
}
.api__item > div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
}
.api__item > div > p {
  color: rgb(255, 0, 0);
  font-weight: 700;
}
.api__item > .api__type {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 2px 6px;
  background-color: #ffffff;
  color: #000;
}

.api__default {
  color: #57ce3a;
}

.api__description {
  width: 100%;
  text-align: left;
  color: #ffffff;
  font-size: 16px;
}
.api__description > span {
  color: #57ce3a;
}

.api__path {
  max-width: 100%;
  text-align: left;
  border-radius: 4px;
  padding: 2px 6px;
  background-color: rgba(0, 0, 0, 0.3);
  color: #ffffff;
  font-size: 16px;
  transition: all 0.2s ease 0s;
  cursor: pointer;
}
.api__path:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.api__docs {
  width: 100%;
  text-align: left;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .apiDocs__container {
    gap: 20px;
  }
  .apiDocs__title {
    font-size: 28px;
  }
  .apiDocs__subtitle {
    font-size: 22px;
  }
  .apiDocs__content {
    border-radius: 16px;
    padding: 8px;
  }
  .api__point {
    border-radius: 8px;
  }
  .szh-accordion__item-heading > button {
    padding: 10px 12px;
    border-radius: 8px;
  }
  .apiDocs__name {
    font-size: 18px;
  }
  .api__body {
    padding: 12px;
    gap: 14px;
  }
  .api__text {
    font-size: 14px;
  }
  .api__items {
    font-size: 12px;
    padding: 12px;
    border-radius: 8px;
    overflow-x: auto;
  }
  .api__names {
    padding-bottom: 12px;
    grid-template-columns: 100px 70px 70px 300px;
    gap: 8px;
  }
  .api__item {
    width: fit-content;
    padding: 12px 0;
    grid-template-columns: 100px 70px 70px 300px;
    gap: 8px;
  }
  .api__path,
  .api__description {
    font-size: 14px;
  }
}
@media screen and (max-width: 520px) {
  .api__path {
    font-size: 12px;
  }
}
@media screen and (max-width: 380px) {
  .api__path {
    font-size: 10px;
  }
}
.api__text:last-child > span {
  background-color: transparent;
  padding: 0;
  color: #57ce3a;
}

.modal-select .dialog.dialogInfo {
  max-width: 420px;
}

.admin__note {
  width: 100%;
  text-align: left;
}

table.fixedtable tbody td sup {
  background-color: rgba(0, 0, 0, 0.35);
  padding: 4px;
  border-radius: 4px;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 15;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.2s ease 0s;
  z-index: 100;
}

.popup.open {
  opacity: 1;
  visibility: visible;
}

.popup.open .popup__content {
  opacity: 1;
  transform: translateY(0);
}

.popup__body {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
}

.popup__content {
  width: 100%;
  position: relative;
  opacity: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background: #1d2129;
  border-radius: 15px;
  transform: translateY(-10px);
  transition: 0.2s ease transform;
  padding: 20px 45px 35px 45px;
}
.popup__content.__loading {
  opacity: 0.8;
  pointer-events: none;
}

.popup__withdraw {
  max-width: 550px;
}

.popup__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.popup__header > span {
  text-align: left;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
}
.popup__header > button {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

.popup__balance {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  padding: 20px 0;
  position: relative;
  margin-bottom: 25px;
}
.popup__balance::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 50%, rgba(255, 255, 255, 0) 100%);
}
.popup__balance > p {
  font-weight: 600;
  font-size: 16px;
  color: #fff;
}
.popup__balance > span {
  font-weight: 600;
  font-size: 16px;
  color: #fab718;
}

.select {
  width: 100%;
  position: relative;
}
.select._active > button {
  background: #feb302;
}
.select._active > button::before {
  transform: rotate(180deg);
}
.select > button {
  width: 100%;
  position: relative;
  gap: 20px;
  padding: 14px 20px;
  background: #2f323d;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease 0s;
}
.select > button::before {
  content: "";
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  width: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='10' viewBox='0 0 15 10' fill='none' %3E%0A%3Cpath d='M1.66211 1.37708L7.91602 7.63123L14.1702 1.37708' stroke='white' stroke-width='2'/%3E%0A%3C/svg%3E%0A");
  transition: all 0.2s ease 0s;
}
.select > button > svg,
.select > button > img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  object-position: center;
}
.select > button > span {
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select__items {
  position: absolute;
  width: 100%;
  left: 0;
  top: calc(100% + 10px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background: #2f323d;
  border-radius: 5px;
  padding: 10px;
  animation: fadeIn 0.2s ease 0s forwards;
  z-index: 20;
  -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.select__item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 10px;
  border-radius: 5px;
  transition: all 0.2s ease 0s;
  cursor: pointer;
}
.select__item:hover {
  background: #5d606a;
}
.select__item > svg,
.select__item > img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  object-position: center;
}
.select__item > span {
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.popup__form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding-top: 25px;
  margin-top: 25px;
}
.popup__form::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 2px;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 50%, rgba(255, 255, 255, 0) 100%);
}

.popup__bnt {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  background: #fab718;
  font-weight: 600;
  font-size: 20px;
  color: #1f222b;
  transition: all 0.2s ease 0s;
  cursor: pointer;
  outline: none;
  border: none;
  padding: 18px;
}
.popup__bnt:hover {
  background: #fbca58;
}

.input-block {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}
.input-block > span {
  width: 100%;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  color: #fff;
}

.input-container {
  width: 100%;
  position: relative;
}
.input-container > button {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #fab718;
  font-weight: 600;
  font-size: 10px;
  color: #1f222b;
  height: fit-content;
  margin: auto 0;
  border: none;
  outline: none;
  width: 36px;
  height: 32px;
  cursor: pointer;
  transition: all 0.2s ease 0s;
}
.input-container > button:hover {
  background: #fbca58;
}
.input-container > button + input {
  padding-right: 60px;
}
.input-container > input {
  width: 100%;
  background: #2f323d;
  border-radius: 5px;
  transition: all 0.2s ease 0s;
  padding: 14px 20px 14px 60px;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  border: 1px solid transparent;
  outline: none;
}
.input-container > input:hover {
  border-color: #ffffff;
}
.input-container > input:focus {
  border-color: #fab718;
}
.input-container > input:disabled {
  pointer-events: none;
  opacity: 0.6;
}
.input-container > svg,
.input-container > img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20px;
  width: 20px;
  height: 20px;
  object-fit: contain;
  object-position: center;
  margin: auto 0;
}

.input-block-mb {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .popup__content {
    padding: 20px 16px 25px 16px;
  }
}
.small-icon {
  max-width: 16px;
  max-height: 16px;
}

.devices {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.devices__items {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background: rgba(29, 33, 41, 0.3);
  color: #ffffff;
  border-radius: 16px;
  overflow: hidden;
}

.device__item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-bottom: 1px solid #ffffff;
}
.device__item:last-child {
  border-bottom: none;
}
.device__item > div {
  width: 100%;
}

.deviceMain .szh-accordion__item-heading > button {
  background: transparent;
  padding: 0;
  border-radius: 0;
  cursor: auto;
}

.deviceRequisites,
.deviceBank__header,
.deviceMain__header {
  width: 100%;
  transition: all 0.2s ease 0s;
}
.deviceRequisites:hover,
.deviceBank__header:hover,
.deviceMain__header:hover {
  background: rgba(82, 82, 82, 0.2);
}
.deviceRequisites.__disabled,
.deviceBank__header.__disabled,
.deviceMain__header.__disabled {
  /* background: rgba(82, 82, 82, 0.2); */
}
.deviceRequisites.__disabled .device__content,
.deviceRequisites.__disabled .device__status,
.deviceBank__header.__disabled .device__content,
.deviceBank__header.__disabled .device__status,
.deviceMain__header.__disabled .device__content,
.deviceMain__header.__disabled .device__status {
  opacity: 0.3;
}

.device__layout {
  width: 100%;
  display: grid;
  grid-template-columns: 90px 84px repeat(3, 1fr) 164px;
  align-items: stretch;
}

.device__layout > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 12px;
}

.deviceBank__item .device__layout > div {
  padding-top: 12px;
  padding-bottom: 12px;
}

.deviceBank__item {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.deviceBank__item:nth-child(2) {
  border-top: none;
}

.deviceRequisites {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.deviceRequisites:nth-child(2) {
  border-top: none;
}

.deviceBank__header .device__status svg {
  transition: all 0.2s ease 0s;
}

.deviceBank__item.szh-accordion__item--expanded .deviceBank__header .device__status svg {
  transform: rotate(90deg);
}

.device__layout > .device__status {
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 14px;
}

.device__content {
  color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  font-size: 14px;
}

.szh-accordion__item--expanded.deviceMain {
  background-color: rgba(0, 0, 0, 0.25);
}

.device__layout > .device__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
}
.device__layout > .device__actions > div {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex !important;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease 0s;
  cursor: pointer;
}
.device__layout > .device__actions > div > div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}
.device__layout > .device__actions > div:hover {
  background-color: rgba(0, 0, 0, 0.75);
}

.device__layout > div.device__header {
  width: 100%;
  text-align: left;
  font-size: 14px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: rgb(195, 195, 195);
}

.device__progress {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
}

.deviceMain > .szh-accordion__item-heading {
  border-bottom: 1px solid transparent;
  transition: all 0.2s ease 0s;
}

.szh-accordion__item--expanded.deviceMain > .szh-accordion__item-heading {
  border-bottom: 1px solid #ffffff;
  background: rgba(144, 144, 144, 0.4);
}

.szh-accordion__item--expanded.deviceBank__item > .szh-accordion__item-heading {
  border-bottom: 1px solid #ffffff;
  background: rgba(144, 144, 144, 0.2);
}

.devices__head {
  width: 100%;
  justify-content: space-between;
}

.deviceBtn {
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  background: #fab718;
  font-weight: 600;
  font-size: 16px;
  color: #1f222b;
  transition: all 0.2s ease 0s;
  cursor: pointer;
  outline: none;
  border: none;
  padding: 12px;
}
.deviceBtn:hover {
  background: #fbca58;
}

@media screen and (max-width: 820px) {
  .devices__head {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
  }
  .deviceBtn {
    margin-left: 0;
  }
  .devices__items {
    overflow-x: auto;
  }
  .device__item,
  .device__layout {
    min-width: 900px;
  }
}
.popup__device {
  max-width: 640px;
}

.step {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 24px;
}

.step__item {
  position: relative;
  text-align: left;
  font-size: 16px;
  color: #b1b1b1;
  transition: all 0.2s ease 0s;
  cursor: pointer;
}
.step__item.__active {
  color: #fbca58;
}

.step__line {
  width: auto;
  flex: 1 1 auto;
  height: 1px;
  background: #b1b1b1;
  position: relative;
  overflow: hidden;
}
.step__line::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background-color: #fbca58;
  transition: all 0.2s ease 0s;
}
.step__line.__active::before {
  width: 100%;
}

.step__navigation {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 12px;
}
.step__navigation > button {
  flex: 1 1 50%;
}

.device__tab {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 24px;
}

.device__info {
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  margin-bottom: 12px;
}

.device__filter {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
}
.device__filter > div {
  min-width: 142px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;
}
.device__filter select {
  width: 100%;
}

.spinner__device {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 24px;
}

body,
html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  font: 500 18px/21px "Gilroy", sans-serif;
  color: #fff;
}
body::before {
  content: "";
  /* background: #171a21 url('../../assets/img/bg.png'); */
  background-color: #311e29;
  background-image: linear-gradient(43deg, #311e29 0%, #3c242f 20%, #531c30 54%, #47303d 83%, #343542 100%);
  background-size: cover;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}
body.second::before {
  /* background: #171a21 url('../../assets/img/bg2.png'); */
  background-color: #311e29;
  background-image: linear-gradient(43deg, #311e29 0%, #3c242f 20%, #531c30 54%, #47303d 83%, #343542 100%);
  background-size: cover;
}

.container {
  max-width: 1400px;
  padding: 10px 25px;
  margin: 0 auto;
}

.section-about {
  max-width: 500px;
}

.section-settings {
  max-width: 500px;
}

