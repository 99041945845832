.popup {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 15;
	background: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(5px);
	top: 0;
	left: 0;
	opacity: 0;
	visibility: hidden;
	overflow-y: auto;
	overflow-x: hidden;
	transition: all 0.2s ease 0s;
	z-index: 100;
}
.popup.open {
	opacity: 1;
	visibility: visible;
}
.popup.open .popup__content {
	opacity: 1;
	transform: translateY(0);
}
.popup__body {
	min-height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px 10px;
}
.popup__content {
	width: 100%;
	position: relative;
	opacity: 0;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	background: #1d2129;
	border-radius: 15px;
	transform: translateY(-10px);
	transition: 0.2s ease transform;
	padding: 20px 45px 35px 45px;
	&.__loading {
		opacity: 0.8;
		pointer-events: none;
	}
}
.popup__withdraw {
	max-width: 550px;
}
.popup__header {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	> span {
		text-align: left;
		color: #ffffff;
		font-size: 20px;
		font-weight: 600;
	}
	> button {
		width: 24px;
		height: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: transparent;
		outline: none;
		border: none;
		cursor: pointer;
	}
}
.popup__balance {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 15px;
	padding: 20px 0;
	position: relative;
	margin-bottom: 25px;
	&::before {
		position: absolute;
		content: '';
		left: 0;
		bottom: 0;
		width: 100%;
		height: 2px;
		background: rgb(255, 255, 255);
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 0.75) 50%,
			rgba(255, 255, 255, 0) 100%
		);
	}
	> p {
		font-weight: 600;
		font-size: 16px;
		color: #fff;
	}
	> span {
		font-weight: 600;
		font-size: 16px;
		color: #fab718;
	}
}
.select {
	width: 100%;
	position: relative;
	&._active {
		> button {
			background: #feb302;
			&::before {
				transform: rotate(180deg);
			}
		}
	}
	> button {
		width: 100%;
		position: relative;
		gap: 20px;
		padding: 14px 20px;
		background: #2f323d;
		border-radius: 5px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 20px;
		border: none;
		outline: none;
		cursor: pointer;
		transition: all 0.2s ease 0s;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			right: 10px;
			bottom: 0;
			width: 15px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='10' viewBox='0 0 15 10' fill='none' %3E%0A%3Cpath d='M1.66211 1.37708L7.91602 7.63123L14.1702 1.37708' stroke='white' stroke-width='2'/%3E%0A%3C/svg%3E%0A");
			transition: all 0.2s ease 0s;
		}
		> svg,
		> img {
			width: 20px;
			height: 20px;
			object-fit: contain;
			object-position: center;
		}
		> span {
			font-weight: 500;
			font-size: 14px;
			color: #fff;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}
.select__items {
	position: absolute;
	width: 100%;
	left: 0;
	top: calc(100% + 10px);
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	background: #2f323d;
	border-radius: 5px;
	padding: 10px;
	animation: fadeIn 0.2s ease 0s forwards;
	z-index: 20;
	-webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
	box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
}
@keyframes fadeIn {
	0% {
		opacity: 0;
		transform: translateY(-10px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
.select__item {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 20px;
	padding: 10px;
	border-radius: 5px;
	transition: all 0.2s ease 0s;
	cursor: pointer;
	&:hover {
		background: #5d606a;
	}
	> svg,
	> img {
		width: 20px;
		height: 20px;
		object-fit: contain;
		object-position: center;
	}
	> span {
		font-weight: 500;
		font-size: 14px;
		color: #fff;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
.popup__form {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: relative;
	padding-top: 25px;
	margin-top: 25px;
	&::before {
		position: absolute;
		content: '';
		left: 0;
		top: 0;
		width: 100%;
		height: 2px;
		background: rgb(255, 255, 255);
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 0.75) 50%,
			rgba(255, 255, 255, 0) 100%
		);
	}
}
.popup__bnt {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	border-radius: 10px;
	background: #fab718;
	font-weight: 600;
	font-size: 20px;
	color: #1f222b;
	transition: all 0.2s ease 0s;
	cursor: pointer;
	outline: none;
	border: none;
	padding: 18px;
	&:hover {
		background: #fbca58;
	}
}
.input-block {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	gap: 10px;
	margin-bottom: 20px;
	> span {
		width: 100%;
		text-align: left;
		font-weight: 600;
		font-size: 16px;
		color: #fff;
	}
}
.input-container {
	width: 100%;
	position: relative;
	> button {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 4px;
		background: #fab718;
		font-weight: 600;
		font-size: 10px;
		color: #1f222b;
		height: fit-content;
		margin: auto 0;
		border: none;
		outline: none;
		width: 36px;
		height: 32px;
		cursor: pointer;
		transition: all 0.2s ease 0s;
		&:hover {
			background: #fbca58;
		}
	}
	> button + input {
		padding-right: 60px;
	}
	> input {
		width: 100%;
		background: #2f323d;
		border-radius: 5px;
		transition: all 0.2s ease 0s;
		padding: 14px 20px 14px 60px;
		font-weight: 500;
		font-size: 14px;
		color: #fff;
		border: 1px solid transparent;
		outline: none;
		&:hover {
			border-color: #ffffff;
		}
		&:focus {
			border-color: #fab718;
		}
		&:disabled {
			pointer-events: none;
			opacity: 0.6;
		}
	}
	> svg,
	> img {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 20px;
		width: 20px;
		height: 20px;
		object-fit: contain;
		object-position: center;
		margin: auto 0;
	}
}
.input-block-mb {
	margin-bottom: 0;
}
@media screen and (max-width: 768px) {
	.popup__content {
		padding: 20px 16px 25px 16px;
	}
}
.small-icon {
	max-width: 16px;
	max-height: 16px;
}
.devices {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
}
.devices__items {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	background: rgba(29, 33, 41, 0.3);
	color: #ffffff;
	border-radius: 16px;
	overflow: hidden;
}
.device__item {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	border-bottom: 1px solid #ffffff;
	&:last-child {
		border-bottom: none;
	}
	> div {
		width: 100%;
	}
}
.deviceMain .szh-accordion__item-heading > button {
	background: transparent;
	padding: 0;
	border-radius: 0;
	cursor: auto;
}
.deviceRequisites,
.deviceBank__header,
.deviceMain__header {
	width: 100%;
	transition: all 0.2s ease 0s;
	&:hover {
		background: rgba(82, 82, 82, 0.2);
	}
	&.__disabled {
		.device__content,
		.device__status {
			opacity: 0.3;
		}
		/* background: rgba(82, 82, 82, 0.2); */
	}
}
.device__layout {
	width: 100%;
	display: grid;
	grid-template-columns: 90px 84px repeat(3, 1fr) 164px;
	align-items: stretch;
}
.device__layout > div {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 16px 12px;
}
.deviceBank__item .device__layout > div {
	padding-top: 12px;
	padding-bottom: 12px;
}
.deviceBank__item {
	border-top: 1px solid rgba(255, 255, 255, 0.1);
	&:nth-child(2) {
		border-top: none;
	}
}
.deviceRequisites {
	border-top: 1px solid rgba(255, 255, 255, 0.1);
	&:nth-child(2) {
		border-top: none;
	}
}
.deviceBank__header .device__status svg {
	transition: all 0.2s ease 0s;
}
.deviceBank__item.szh-accordion__item--expanded
	.deviceBank__header
	.device__status
	svg {
	transform: rotate(90deg);
}
.device__layout > .device__status {
	color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 4px;
	font-size: 14px;
}
.device__content {
	color: #ffffff;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	text-align: left;
	font-size: 14px;
}
.szh-accordion__item--expanded.deviceMain {
	background-color: rgba(0, 0, 0, 0.25);
}
.device__layout > .device__actions {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 4px;
	> div {
		border-radius: 8px;
		background-color: rgba(0, 0, 0, 0.25);
		display: flex !important;
		justify-content: center;
		align-items: center;
		transition: all 0.2s ease 0s;
		cursor: pointer;
		> div {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 8px;
		}
		&:hover {
			background-color: rgba(0, 0, 0, 0.75);
		}
	}
}
.device__layout > div.device__header {
	width: 100%;
	text-align: left;
	font-size: 14px;
	padding-top: 12px;
	padding-bottom: 12px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	color: rgb(195, 195, 195);
}
.device__progress {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	text-align: left;
}
.deviceMain > .szh-accordion__item-heading {
	border-bottom: 1px solid transparent;
	transition: all 0.2s ease 0s;
}
.szh-accordion__item--expanded.deviceMain > .szh-accordion__item-heading {
	border-bottom: 1px solid #ffffff;
	background: rgba(144, 144, 144, 0.4);
}
.szh-accordion__item--expanded.deviceBank__item > .szh-accordion__item-heading {
	border-bottom: 1px solid #ffffff;
	background: rgba(144, 144, 144, 0.2);
}
.devices__head {
	width: 100%;
	justify-content: space-between;
}
.deviceBtn {
	margin-left: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	border-radius: 10px;
	background: #fab718;
	font-weight: 600;
	font-size: 16px;
	color: #1f222b;
	transition: all 0.2s ease 0s;
	cursor: pointer;
	outline: none;
	border: none;
	padding: 12px;
	&:hover {
		background: #fbca58;
	}
}
@media screen and (max-width: 820px) {
	.devices__head {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		flex-direction: column;
		gap: 16px;
	}
	.deviceBtn {
		margin-left: 0;
	}
	.devices__items {
		overflow-x: auto;
	}
	.device__item,
	.device__layout {
		min-width: 900px;
	}
}
.popup__device {
	max-width: 640px;
}
.step {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	margin-top: 24px;
}
.step__item {
	position: relative;
	text-align: left;
	font-size: 16px;
	color: #b1b1b1;
	transition: all 0.2s ease 0s;
	cursor: pointer;
	&.__active {
		color: #fbca58;
	}
}
.step__line {
	width: auto;
	flex: 1 1 auto;
	height: 1px;
	background: #b1b1b1;
	position: relative;
	overflow: hidden;
	&::before {
		content: '';
		position: absolute;
		width: 0;
		height: 100%;
		background-color: #fbca58;
		transition: all 0.2s ease 0s;
	}
	&.__active {
		&::before {
			width: 100%;
		}
	}
}
.step__navigation {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 16px;
	margin-top: 12px;
	> button {
		flex: 1 1 50%;
	}
}
.device__tab {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	margin-top: 24px;
}
.device__info {
	width: 100%;
	text-align: center;
	font-weight: 600;
	font-size: 16px;
	color: #fff;
	margin-bottom: 12px;
}
.device__filter {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 16px;
	> div {
		min-width: 142px;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		flex-direction: column;
		gap: 12px;
	}
	select {
		width: 100%;
	}
}
.spinner__device{
	width: 100%;
	display:flex;
	justify-content:center;
	align-items: center;
	margin-top: 12px;
	margin-bottom: 24px;
}